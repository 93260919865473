
.checkbox_item .checkbox_wrap{
	position: relative;
	display: block;
	cursor: pointer;
	width: fit-content;
}

.checkbox_item:last-child .checkbox_wrap{
	margin-bottom: 0;
}

.checkbox_item .checkbox_wrap .checkbox_inp{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 1;
}

.checkbox_item .checkbox_wrap .checkbox_mark{
	display: inline-block;
	position: relative;
	border-radius: 25px;
}

.checkbox_item .checkbox_wrap .checkbox_mark:before,
.checkbox_item .checkbox_wrap .checkbox_mark:after{
	content: "";
	position: absolute;
	transition: all 0.5s ease;
}



/* basic styles */
.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark{
	background: rgb(211,211,211);
	width: 36px;
	height: 20px;
	padding: 2px;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark:before{
	top: 3px;
	left: 3px;
	width: 14px;
	height: 14px;
	background: #f6f4f4;
	border-radius: 50%;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark{
	background: #071fd3;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:before{
	left: 18px;
}
.fs14{
    font-size: 14px;
}
.openout{
	transition: 0.7s ease-in-out ;
}

.transition500{
	transition:0.5s ease-in-out  ;
}